.exception-wrapper {
  display: flex;
  gap: 6px;
  align-items: center;
  height: 28px;
  line-height: 20px;
}

.exception-wrapper > span:first-child {
  font-weight: bold;
  letter-spacing: 2px;
  display: inline-block;
  width: 50px;
}
