.star-rating {
  display: flex;
}

.star-rating.large {
  font-size: 40px;
  height: 40px;
}

.star-rating.large > span {
  line-height: 40px;
  display: inline-block;
}

.star-rating.large > span:last-of-type {
  color: #b2b2b2;
  margin-right: 6px;
}
