.App {
  font-family: "Calibri";
  background-color: $app-background-color;

  .App-header {
    background-color: white;
    min-width: $menu-width;
    max-width: $menu-width;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    z-index: 2;
  }

  .App-main {
    margin-left: $menu-width;
    width: calc(100% - #{$menu-width});
    min-height: 100vh;
  }
}

.App-login {
  height: 100vh;
  background-image: (url('../images/login_bg.svg'));
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: 70% 100vh;

  footer {
    height: 200px;
    background-color: whitesmoke;

    .footer-links {
      color: $link-gray;
      a {
        color: $link-gray;
        text-transform: uppercase;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.App-brand {
  .App-name {
    color: $primary;
  }
}

.LTCollapse--collapse {
  transition: height 500ms;
}

.loadable-content--wrapper {
  .is-overlay {
    progress.progress {
      margin: 40vh auto;
      width: 70%;
      border-radius: $radius;
    }
  }
}

.header-div {
  display: grid;
  grid-template-areas: 
  'header1 header2'
  ;
}
.header-div > .mb-2 {
  font-weight: 600;
}
.item1 { grid-area: header1; }
.item2 { 
  grid-area: header2; 
  text-align: right;
}

.site-label {
  width: 100%;
}

.border-bottom {
  border-bottom: 1px solid grey;
}