.month-year-range, .month-year-picker {

  button {
    font-family: "LT Trim Regular", "Barlow", Calibri;
    font-weight: 700;
    text-transform: capitalize;
  }

  .icon {
    color: $primary;
  }
}

.field.required label.label::after {
  content: '*';
  color: tomato;
  margin-left: 5px;
  position: relative;
  top: -5px;
}

label.radio {
  margin-right: 1.5em;
  input[type=radio] {
    margin-right: 0.5em;
  }
}

.login-input {
  background-color: #fafbfb;
}

.login-input:focus {
  border-color: #8a8a8a;
  box-shadow: none;
}

.login-error {
  color: #f64e3c;
}