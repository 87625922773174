.checkbox-wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  cursor: pointer;
  align-items: center;
}

.checkbox-wrapper .checkbox-label {
  user-select: none;
}

.checkbox-wrapper .checkbox {
  width: 56px;
  height: 28px;
  border: 1px solid #c6c6c6;
  border-radius: 20px;
  padding: 2px;
  transition: all 0.5s;
}

.checkbox-wrapper .checkbox .ball {
  width: 22px;
  height: 22px;
  background-color: #c6c6c6;
  border-radius: 30px;
  position: relative;
  left: 0;
  transition: all 0.5s;
}

.checkbox-wrapper.checked .checkbox {
  border: 1px solid #003087;
  background-color: #003087;
}

.checkbox-wrapper.checked .checkbox .ball {
  background-color: #ffffff;
  left: calc(100% - 22px);
}
