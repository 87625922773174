.react-datepicker.lt-calendar {
  width: 220px;
  border-radius: 0;

  .react-datepicker__header.react-datepicker-year-header {
    background-color: white;
  }

  .react-datepicker__month-text {
    border-radius: 0;

    &.react-datepicker__month--in-range {
      background-color: $primary;
    }

    &.react-datepicker__month-text--keyboard-selected {
      background-color: gray;
    }
  }

  .react-datepicker__triangle::after {
    border-bottom-color: white;
  }
}