.bar-wrapper {
  display: flex;
  flex-direction: row;
  height: 36px;
  overflow: hidden;
  border-radius: 5px;
}

.bar-wrapper.small {
  height: 24px;
}

.bar-wrapper.small .bar-green span {
  font-size: 16px;
  line-height: 16px;
}

.bar-wrapper > * {
  flex-grow: 1;
}

.bar-wrapper .bar-green {
  background-color: #279b31;
}
.bar-wrapper .bar-green span {
  font-size: 24px;
  color: white;
  font-weight: 400;
  padding-left: 16px;
  line-height: 36px;
}

.bar-wrapper .bar-yellow {
  background-color: #ffd100;
}

.bar-wrapper .bar-red {
  background-color: #b31f1f;
}
