.menu-list {
  li {
    a {
      font-weight: bold;
      color: $link-gray;

      &.active {
        background-color: white;
        color: $primary;
        border-left: 6px solid $primary;
      }
    }
  }
}

.menu-list:not(.bottom-list) {
  li {
    margin-bottom: 1em;
  }
}

.menu-list.bottom-list {
  margin-top: 100px;
}

.menu-list {
  a {
    padding-left: 1.5em;
    border-left: 6px solid transparent;
  }

  .current-user {
    padding-left: 2.75em;
  }
}

.tabs {
  li a.active {
    color: $primary;
    border-color: $primary;
  }
}