.cards-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cards-wrapper > * {
  min-height: 286px;
  flex: 1 1 200px;
  box-sizing: border-box;
}


.cards-wrapper > .box.p-5.mb-5 {
  max-width: 50%;
}

.rating {
  display: flex;
  gap: 6px;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
}

.table tr,
.table td {
  vertical-align: middle !important;
}

.table-title {
  width: 100%;
}

.table-title > *:last-child {
  margin-left: auto;
}

.react-datepicker__year-text--disabled {
  visibility: hidden !important; /* container will keep its height */
  display: none !important; /* container will shrink to fit content */
}

.react-datepicker__year-wrapper {
  max-width: none !important;
}

.table .is-unselectable {
  font-size: 14px
}

.calendar-tracking-table td:first-child {
  border-width: 0 1px 1px 0 ;
}