$box-radius: 0;
$radius: 0;

$primary: #003087;
$link: $primary;
$danger: #B31F1F;
$warning: #FFD100;
$success: #3D7824;

$tabs-border-bottom-width: 4px;
$message-body-padding: 0.5em;


button.is-ghost:focus {
  box-shadow: none!important;
}

.modal-card-head {
  border: none!important;
}

body {
  color: hsl(0, 0%, 10%, 1)!important;
}

.box {
  color: hsl(0, 0%, 10%, 1)!important;
}

input[type="checkbox"] {
  accent-color: #003087
}