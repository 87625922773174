table.table {
  font-size: 0.85em;

  tbody td {
    font-family: Barlow, Calibri;
    border-color: $light-grey;
  }

  thead tr {
    background-color: $gray-highlight;

    th {
      padding: 1em;
      border: none;
      font-size: 0.9em;
    }
  }
}