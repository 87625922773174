/* Webfonts: Copyright © 2012 by Letters From Sweden. All rights reserved. To obtain a license, please see: http://lettersfromsweden.se/webfonts */

@font-face {
  font-family: 'LT Trim Bold';
  src: url('../fonts/LTTrimWeb-Bold.eot');
  src: url('../fonts/LTTrimWeb-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/LTTrimWeb-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'LT Trim Regular';
  src: url('../fonts/LTTrimWeb-Regular.eot');
  src: url('../fonts/LTTrimWeb-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/LTTrimWeb-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// Barlow for body text
@import url('https://fonts.googleapis.com/css2?family=Barlow&display=swap');

* {
  font-family: 'LT Trim Regular', 'Barlow', Calibri;
}

button {
  font-family: 'LT Trim Regular', 'Barlow', Calibri;
}

p, select {
  font-family: 'Barlow', Calibri;
}