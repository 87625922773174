.label-value {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 16px 0 6px 0;
}

.label-value > span:first-child {
  font-weight: bold;
}
