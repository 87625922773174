.bar-wrapper {
  display: flex;
  flex-direction: row;
  height: 36px;
  overflow: hidden;
}

.bar-wrapper.tiny {
  height: 14px;
  border-radius: 8px;
  margin: 24px 0px;
}

.bar-wrapper .bar-navy {
  background-color: #002b7a;
}
.bar-wrapper .bar-navy span {
  font-size: 24px;
  color: white;
  font-weight: 400;
  padding-left: 16px;
  line-height: 36px;
}

.bar-wrapper .bar-blue {
  background: linear-gradient(90deg, #5c81c5 0%, #4970b7 44.5%);
}

.bar-wrapper .bar-lightBlue {
  background: linear-gradient(90deg, #a7c2f5 0%, #99b0db 28%);
}
